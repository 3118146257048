import React, { useState } from 'react';

import { calendar, isLoading } from './dashboard.module.scss';

import { IActivation } from '../../models/activation.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { getUserTokenData } from '../../utils/get-user-token-data';

import PanelLayout from '../../layouts/panel-layout';
import PanelCalendar from '../organisms/panel-calendar';

type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

const Dashboard = () => {
    const t = useTranslations('Dashboard');
    const [value, onChange] = useState<Value>(new Date());
    const token = getUserTokenData()?.token;

    const { data, status, handleChange } = useList<IActivation>({
        endpoint: '/activations/calendar',
        token: token,
        perPage: 999,
        initialParams: { year: getInitialParams().year, month: getInitialParams().month },
    });
    const items = data ? Object.values(data).filter((item) => item) : [];

    const getActivations = async (month: number, year: number) => {
        const yearString = year.toString();
        const monthString = month < 10 ? `0${month}` : month.toString();
        handleChange({ year: yearString, month: monthString });
    };

    return (
        <PanelLayout
            summary={'list'}
            showHeaderDate={false}
            showSummaryCalendar={false}
            showSummaryButton={true}
            title={t.title}
            activations={items}
            isLoading={status === 'loading'}
            summaryDate={value}
        >
            <PanelCalendar
                activations={items}
                type={'big'}
                value={value}
                onChange={onChange}
                onMonthChange={getActivations}
                className={`${calendar} ${status === 'loading' ? isLoading : ''}`}
            />
        </PanelLayout>
    );
};

export default Dashboard;

function getInitialParams() {
    const date = new Date();
    const month =
        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    return { year: year, month: month };
}
